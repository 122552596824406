.CustomInput {

	.p-inputtext {
		border: 1px solid #ced4da;
		width: 100%;
		height: 40px;
	}

	.p-inputgroup {
		.p-inputtext {
			border: 1px solid #ced4da;
			border-right: none;

			&:disabled {
				opacity: 0.5;
				background: #fafafa;
				cursor: not-allowed !important;
			}
		}

		.p-inputgroup-addon {
			border: 1px solid #CED4DA !important;
		}
	}


	.p-password {
		width: 100%;

		.p-inputtext {
			width: 100%;
			border: 1px solid #CED4DA;

			&:disabled {
				opacity: 0.5;
				background: #fafafa;
				cursor: not-allowed !important;
			}
		}
	}

	&.invalid {
		$red: #f37171;

		label {
			color: $red;
		}

		.p-inputtext {
			border-color: $red !important;
		}

		.p-inputgroup-addon {
			border-color: $red !important;
			color: $red;
		}
	}
}
