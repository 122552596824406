.p-tabview {
	.p-tabview-nav {
		background: $tabviewNavBg;
		border: $tabviewNavBorder;
		border-width: $tabviewNavBorderWidth;

		li {
			margin-right: $tabviewHeaderSpacing;

			.p-tabview-nav-link {
				border: $tabviewHeaderBorder;
				border-width: $tabviewHeaderBorderWidth;
				border-color: $tabviewHeaderBorderColor;
				background: $tabviewHeaderBg;
				color: $tabviewHeaderTextColor;
				padding: $tabviewHeaderPadding;
				font-weight: $tabviewHeaderFontWeight;
				border-top-right-radius: $borderRadius;
				border-top-left-radius: $borderRadius;
				transition: $listItemTransition;
				margin: $tabviewHeaderMargin;

				&:not(.p-disabled):focus {
					@include focused();
				}
			}

			&:not(.p-highlight):not(.p-disabled):hover {
				.p-tabview-nav-link {
					background: $tabviewHeaderHoverBg;
					border-color: $tabviewHeaderHoverBorderColor;
					color: $tabviewHeaderTextHoverColor;
				}
			}

            &.p-highlight {
                .p-tabview-nav-link {
                    background: $tabviewHeaderActiveBg;
                    border-color: $tabviewHeaderActiveBorderColor;
                    color: $tabviewHeaderTextActiveColor;
                }
            }
        }


        .p-tabview-ink-bar{ // TODO Issue of primeReact style
            background-color: $tabviewHeaderTextActiveColor;
        }
    }

	.p-tabview-panels {
		background: $tabviewContentBg;
		padding: $tabviewContentPadding;
		border: $tabviewContentBorder;
		color: $tabviewContentTextColor;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
	}
}
