@use 'src/theme/variables' as *;

.NavMenu {
	&--title {
		padding: 10px 12px;
	}

	&--item {

		&-active {
			color: $primaryColor;

			i, .TextIcon>span {
				color: $primaryColor !important;
			}

			background: #F7F7F7;
			transform: scale(1.03);
		}

		&:hover {
			background: #f3f3f3;
			transform: scale(1.03);
		}

		&:active {
			transform: scale(1.01);
		}

		padding: 14px;
		color: $text-neutral-black;

		i {
			color: $text-neutral-grey;
		}

		transform: scale(1);
		transition: all 0.1s ease;
	}
}
