.p-button {
	color: $buttonTextColor;
	background: $buttonBg;
	border: $buttonBorder;
	padding: $buttonPadding;
	font-size: $fontSize;
	transition: $formElementTransition;
	border-radius: $borderRadius;
	font-family: Roboto;
	height: $buttonHeight;

	@include button-states {
		&:hover {
			background: $buttonHoverBg;
			color: $buttonTextHoverColor;
			border-color: $buttonHoverBorderColor;
		}

		&:active {
			background: $buttonActiveBg;
			color: $buttonTextActiveColor;
			border-color: $buttonActiveBorderColor;
		}
	}

	&.p-button-outlined {
		background-color: transparent;
		color: $buttonBg;
		border: $outlinedButtonBorder;

		@include button-states {
			&:hover {
				background: rgba($buttonBg, $textButtonHoverBgOpacity);
				color: $buttonBg;
				border: $outlinedButtonBorder;
			}

			&:active {
				background: rgba($buttonBg, $textButtonActiveBgOpacity);
				color: $buttonBg;
				border: $outlinedButtonBorder;
			}
		}

		&.p-button-plain {
			color: $plainButtonTextColor;
			border-color: $plainButtonTextColor;

			@include button-states {
				&:hover {
					background: $plainButtonHoverBgColor;
					color: $plainButtonTextColor;
				}

				&:active {
					background: $plainButtonActiveBgColor;
					color: $plainButtonTextColor;
				}
			}
		}
	}

	&.p-button-text {
		background-color: transparent;
		color: $buttonBg;
		border-color: transparent;

		@include button-states {
			&:hover {
				background: rgba($buttonBg, $textButtonHoverBgOpacity);
				color: $buttonBg;
				border-color: transparent;
			}

			&:active {
				background: rgba($buttonBg, $textButtonActiveBgOpacity);
				color: $buttonBg;
				border-color: transparent;
			}
		}

		&.p-button-plain {
			color: $plainButtonTextColor;

			@include button-states {
				&:hover {
					background: $plainButtonHoverBgColor;
					color: $plainButtonTextColor;
				}

				&:active {
					background: $plainButtonActiveBgColor;
					color: $plainButtonTextColor;
				}
			}
		}
	}

	&:focus {
		@include focused();
	}

	.p-button-icon-left {
		margin-right: $inlineSpacing;
	}

	.p-button-icon-right {
		margin-left: $inlineSpacing;
	}

	.p-button-icon-bottom {
		margin-top: $inlineSpacing;
	}

	.p-button-icon-top {
		margin-bottom: $inlineSpacing;
	}

	.p-badge {
		margin-left: $inlineSpacing;
		min-width: $fontSize;
		height: $fontSize;
		line-height: $fontSize;
		color: $buttonBg;
		background-color: $buttonTextColor;
	}

	&.p-button-raised {
		box-shadow: $raisedButtonShadow;
	}

	&.p-button-rounded {
		border-radius: $roundedButtonBorderRadius;
	}

	&.p-button:disabled {
		opacity: 0.2;
		cursor: default;
	}

	&.p-button-icon-only {
		width: $buttonIconOnlyWidth;
		padding: $buttonIconOnlyPadding;

		.p-button-icon-left,
		.p-button-icon-right {
			margin: 0;
		}

		&.p-button-rounded {
			border-radius: 50%;
			height: $buttonIconOnlyWidth;
		}
	}

	&.p-button-sm {
		@include scaledFontSize($fontSize, $scaleSM);
		@include scaledPadding($buttonPadding, $scaleSM);

		.p-button-icon {
			@include scaledFontSize($primeIconFontSize, $scaleSM);
		}
	}

	&.p-button-lg {
		@include scaledFontSize($fontSize, $scaleLG);
		@include scaledPadding($buttonPadding, $scaleLG);

		.p-button-icon {
			@include scaledFontSize($primeIconFontSize, $scaleLG);
		}
	}

	&.p-button-loading-label-only {
		&.p-button-loading-left {
			.p-button-label {
				margin-left: $inlineSpacing;
			}
		}

		&.p-button-loading-right {
			.p-button-label {
				margin-right: $inlineSpacing;
			}
		}

		&.p-button-loading-top {
			.p-button-label {
				margin-top: $inlineSpacing;
			}
		}

		&.p-button-loading-bottom {
			.p-button-label {
				margin-bottom: $inlineSpacing;
			}
		}

		.p-button-loading-icon {
			margin: 0;
		}
	}
}

.p-fluid {
	.p-button {
		width: 100%;
	}

	.p-button-icon-only {
		width: $buttonIconOnlyWidth;
	}

	.p-buttonset {
		display: flex;

		.p-button {
			flex: 1;
		}
	}
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
	color: $secondaryButtonTextColor;
	background: $secondaryButtonBg;
	border: $secondaryButtonBorder;

	@include button-states {
		&:hover {
			background: $secondaryButtonHoverBg;
			color: $secondaryButtonTextHoverColor;
			border-color: $secondaryButtonHoverBorderColor;
		}

		&:focus {
			box-shadow: $secondaryButtonFocusShadow;
		}

		&:active {
			background: $secondaryButtonActiveBg;
			color: $secondaryButtonTextActiveColor;
			border-color: $secondaryButtonActiveBorderColor;
		}
	}

	&.p-button-outlined {
		background-color: transparent;
		color: $secondaryButtonBg;
		border: $outlinedButtonBorder;

		@include button-states {
			&:hover {
				background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
				color: $secondaryButtonBg;
				border: $outlinedButtonBorder;
			}

			&:active {
				background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
				color: $secondaryButtonBg;
				border: $outlinedButtonBorder;
			}
		}
	}

	&.p-button-text {
		background-color: transparent;
		color: $secondaryButtonBg;
		border-color: transparent;

		@include button-states {
			&:hover {
				background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
				border-color: transparent;
				color: $secondaryButtonBg;
			}

			&:active {
				background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
				border-color: transparent;
				color: $secondaryButtonBg;
			}
		}
	}
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
	color: $infoButtonTextColor;
	background: $infoButtonBg;
	border: $infoButtonBorder;

	@include button-states {
		&:hover {
			background: $infoButtonHoverBg;
			color: $infoButtonTextHoverColor;
			border-color: $infoButtonHoverBorderColor;
		}

		&:focus {
			box-shadow: $infoButtonFocusShadow;
		}

		&:active {
			background: $infoButtonActiveBg;
			color: $infoButtonTextActiveColor;
			border-color: $infoButtonActiveBorderColor;
		}
	}

	&.p-button-outlined {
		background-color: transparent;
		color: $infoButtonBg;
		border: $outlinedButtonBorder;

		@include button-states {
			&:hover {
				background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
				color: $infoButtonBg;
				border: $outlinedButtonBorder;
			}

			&:active {
				background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
				color: $infoButtonBg;
				border: $outlinedButtonBorder;
			}
		}
	}

	&.p-button-text {
		background-color: transparent;
		color: $infoButtonBg;
		border-color: transparent;

		@include button-states {
			&:hover {
				background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
				border-color: transparent;
				color: $infoButtonBg;
			}

			&:active {
				background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
				border-color: transparent;
				color: $infoButtonBg;
			}
		}
	}
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
	color: $successButtonTextColor;
	background: $successButtonBg;
	border: $successButtonBorder;

	@include button-states {
		&:hover {
			background: $successButtonHoverBg;
			color: $successButtonTextHoverColor;
			border-color: $successButtonHoverBorderColor;
		}

		&:focus {
			box-shadow: $successButtonFocusShadow;
		}

		&:active {
			background: $successButtonActiveBg;
			color: $successButtonTextActiveColor;
			border-color: $successButtonActiveBorderColor;
		}
	}

	&.p-button-outlined {
		background-color: transparent;
		color: $successButtonBg;
		border: $outlinedButtonBorder;

		@include button-states {
			&:hover {
				background: rgba($successButtonBg, $textButtonHoverBgOpacity);
				color: $successButtonBg;
				border: $outlinedButtonBorder;
			}

			&:active {
				background: rgba($successButtonBg, $textButtonActiveBgOpacity);
				color: $successButtonBg;
				border: $outlinedButtonBorder;
			}
		}
	}

	&.p-button-text {
		background-color: transparent;
		color: $successButtonBg;
		border-color: transparent;

		@include button-states {
			&:hover {
				background: rgba($successButtonBg, $textButtonHoverBgOpacity);
				border-color: transparent;
				color: $successButtonBg;
			}

			&:active {
				background: rgba($successButtonBg, $textButtonActiveBgOpacity);
				border-color: transparent;
				color: $successButtonBg;
			}
		}
	}
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
	color: $warningButtonTextColor;
	background: $warningButtonBg;
	border: $warningButtonBorder;

	@include button-states {
		&:hover {
			background: $warningButtonHoverBg;
			color: $warningButtonTextHoverColor;
			border-color: $warningButtonHoverBorderColor;
		}

		&:focus {
			box-shadow: $warningButtonFocusShadow;
		}

		&:active {
			background: $warningButtonActiveBg;
			color: $warningButtonTextActiveColor;
			border-color: $warningButtonActiveBorderColor;
		}
	}

	&.p-button-outlined {
		background-color: transparent;
		color: $warningButtonBg;
		border: $outlinedButtonBorder;

		@include button-states {
			&:hover {
				background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
				color: $warningButtonBg;
				border: $outlinedButtonBorder;
			}

			&:active {
				background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
				color: $warningButtonBg;
				border: $outlinedButtonBorder;
			}
		}
	}

	&.p-button-text {
		background-color: transparent;
		color: $warningButtonBg;
		border-color: transparent;

		@include button-states {
			&:hover {
				background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
				border-color: transparent;
				color: $warningButtonBg;
			}

			&:active {
				background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
				border-color: transparent;
				color: $warningButtonBg;
			}
		}
	}
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
	color: $helpButtonTextColor;
	background: $helpButtonBg;
	border: $helpButtonBorder;

	@include button-states {
		&:hover {
			background: $helpButtonHoverBg;
			color: $helpButtonTextHoverColor;
			border-color: $helpButtonHoverBorderColor;
		}

		&:focus {
			box-shadow: $helpButtonFocusShadow;
		}

		&:active {
			background: $helpButtonActiveBg;
			color: $helpButtonTextActiveColor;
			border-color: $helpButtonActiveBorderColor;
		}
	}

	&.p-button-outlined {
		background-color: transparent;
		color: $helpButtonBg;
		border: $outlinedButtonBorder;

		@include button-states {
			&:hover {
				background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
				color: $helpButtonBg;
				border: $outlinedButtonBorder;
			}

			&:active {
				background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
				color: $helpButtonBg;
				border: $outlinedButtonBorder;
			}
		}
	}

	&.p-button-text {
		background-color: transparent;
		color: $helpButtonBg;
		border-color: transparent;

		@include button-states {
			&:hover {
				background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
				border-color: transparent;
				color: $helpButtonBg;
			}

			&:active {
				background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
				border-color: transparent;
				color: $helpButtonBg;
			}
		}
	}
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
	color: $dangerButtonTextColor;
	background: $dangerButtonBg;
	border: $dangerButtonBorder;

	@include button-states {
		&:hover {
			background: $dangerButtonHoverBg;
			color: $dangerButtonTextHoverColor;
			border-color: $dangerButtonHoverBorderColor;
		}

		&:focus {
			box-shadow: $dangerButtonFocusShadow;
		}

		&:active {
			background: $dangerButtonActiveBg;
			color: $dangerButtonTextActiveColor;
			border-color: $dangerButtonActiveBorderColor;
		}
	}

	&.p-button-outlined {
		background-color: transparent;
		color: $dangerButtonBg;
		border: $outlinedButtonBorder;

		@include button-states {
			&:hover {
				background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
				color: $dangerButtonBg;
				border: $outlinedButtonBorder;
			}

			&:active {
				background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
				color: $dangerButtonBg;
				border: $outlinedButtonBorder;
			}
		}
	}

	&.p-button-text {
		background-color: transparent;
		color: $dangerButtonBg;
		border-color: transparent;

		@include button-states {
			&:hover {
				background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
				border-color: transparent;
				color: $dangerButtonBg;
			}

			&:active {
				background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
				border-color: transparent;
				color: $dangerButtonBg;
			}
		}
	}
}

.p-button.p-button-link {
	color: $linkButtonColor;
	background: transparent;
	border: transparent;

	@include button-states {
		&:hover {
			background: transparent;
			color: $linkButtonHoverColor;
			border-color: transparent;

			.p-button-label {
				text-decoration: $linkButtonTextHoverDecoration;
			}
		}

		&:focus {
			background: transparent;
			box-shadow: $linkButtonFocusShadow;
			border-color: transparent;
		}

		&:active {
			background: transparent;
			color: $linkButtonColor;
			border-color: transparent;
		}
	}
}
