.p-inputtext {
	font-family: $fontFamily;
	font-size: $inputTextFontSize;
	color: $inputTextColor;
	background: $inputBg;
	padding: $inputPadding;
	border: $inputBorder;
	transition: $formElementTransition;
	appearance: none;
	border-radius: $borderRadius;

	&:enabled:hover {
		border-color: $inputHoverBorderColor;
	}

	&:enabled:focus {
		@include focused-input();
	}

	&.p-invalid.p-component {
		@include invalid-input();
	}

	&.p-inputtext-sm {
		@include scaledFontSize($inputTextFontSize, $scaleSM);
		@include scaledPadding($inputPadding, $scaleSM);
	}

	&.p-inputtext-lg {
		@include scaledFontSize($inputTextFontSize, $scaleLG);
		@include scaledPadding($inputPadding, $scaleLG);
	}
}

.p-float-label > label {
	left: nth($inputPadding, 2);
	color: $inputPlaceholderTextColor;
	transition-duration: $transitionDuration;
}

.p-float-label > label.p-error {
	color: $errorColor;
}

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
	left: nth($inputPadding, 2);
	color: $inputIconColor;
}

.p-input-icon-left > .p-inputtext {
	padding-left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-left.p-float-label > label {
	left: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

.p-input-icon-right > i:last-of-type,
.p-input-icon-right > svg:last-of-type,
.p-input-icon-right > .p-input-suffix {
	right: nth($inputPadding, 2);
	color: $inputIconColor;
}

.p-input-icon-right > .p-inputtext {
	padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

@include placeholder {
	color: #B6C0C9;
	font-family: Roboto;
	font-size: 16px;
}

;

.p-input-filled {
	.p-inputtext {
		background-color: $inputFilledBg;

		&:enabled:hover {
			background-color: $inputFilledHoverBg;
		}

		&:enabled:focus {
			background-color: $inputFilledFocusBg;
		}
	}
}

.p-inputtext-sm {
	.p-inputtext {
		@include scaledFontSize($inputTextFontSize, $scaleSM);
		@include scaledPadding($inputPadding, $scaleSM);
	}
}

.p-inputtext-lg {
	.p-inputtext {
		@include scaledFontSize($inputTextFontSize, $scaleLG);
		@include scaledPadding($inputPadding, $scaleLG);
	}
}
