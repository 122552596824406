.p-orderlist {
	.p-orderlist-controls {
		padding: $panelContentPadding;

		.p-button {
			margin-bottom: $inlineSpacing;
		}
	}

	.p-orderlist-header {
		background: $panelHeaderBg;
		color: $panelHeaderTextColor;
		border: $panelHeaderBorder;
		padding: $panelHeaderPadding;
		font-weight: $panelHeaderFontWeight;
		border-bottom: 0 none;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
	}

	.p-orderlist-list {
		border: $panelContentBorder;
		background: $panelContentBg;
		color: $panelContentTextColor;
		padding: $inputListPadding;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;

		.p-orderlist-item {
			padding: $inputListItemPadding;
			margin: $inputListItemMargin;
			border: $inputListItemBorder;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
			transition: transform $transitionDuration, $listItemTransition;

			&:not(.p-highlight):hover {
				background: $inputListItemHoverBg;
				color: $inputListItemTextHoverColor;
			}

			&:focus {
				@include focused-listitem();
			}

			&.p-highlight {
				color: $highlightTextColor;
				background: $highlightBg;
			}
		}
	}
}

@media screen and (max-width: $orderListBreakpoint) {
	.p-orderlist {
		flex-direction: column;

		.p-orderlist-controls {
			padding: $panelContentPadding;
			flex-direction: row;

			.p-button {
				margin-right: $inlineSpacing;
				margin-bottom: 0;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
