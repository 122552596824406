.LoginForm {
	min-width: 390px;
	max-width: 768px;

	.p-password {
		.p-inputtext {
			width: 100%;
		}
	}
}
