.p-slider {
	background: $sliderBg;
	border: $sliderBorder;
	border-radius: $borderRadius;

	&.p-slider-horizontal {
		height: $sliderHorizontalHeight;

		.p-slider-handle {
			margin-top: (-1 * $sliderHandleHeight * 0.5);
			margin-left: (-1 * $sliderHandleWidth * 0.5);
		}
	}

	&.p-slider-vertical {
		width: $sliderVerticalWidth;

		.p-slider-handle {
			margin-left: (-1 * $sliderHandleWidth * 0.5);
			margin-bottom: (-1 * $sliderHandleHeight * 0.5);
		}
	}

	.p-slider-handle {
		height: $sliderHandleHeight;
		width: $sliderHandleWidth;
		background: $sliderHandleBg;
		border: $sliderHandleBorder;
		border-radius: $sliderHandleBorderRadius;
		transition: $formElementTransition;

		&:focus {
			@include focused();
		}
	}

	.p-slider-range {
		background: $sliderRangeBg;
	}

	&:not(.p-disabled) {
		.p-slider-handle:hover {
			background: $sliderHandleHoverBg;
			border-color: $sliderHandleHoverBorderColor;
		}
	}
}
