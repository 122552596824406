.StepTwo {
	&-warning {
		&-title {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 150%;
			display: flex;
			align-items: center;
			color: #CE1127;
		}
		&-list {
			&-message, &-target {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				display: flex;
				align-items: center;
				color: #98A2B3;
			}

			&-target {
				list-style: circle;
			}
		}
	}
}
