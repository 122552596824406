.UserForm {
	display: grid;
	grid-gap: 14px;
	grid-auto-rows: minmax(min-content, max-content);
	grid-template-columns: 1fr 1fr;

	&__input {

		&--full {
			grid-column: span 2;
		}

		&--half {
			grid-column: span 1;
		}
	}
}
