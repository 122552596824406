@use './mixins/breakpoint';
@import 'variables';
@import 'fonts';
@import 'extensions';
@import 'components';


::placeholder {
	color: $gray-400 !important;
	font-family: 'Inter', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 100%;
}

* {
	font-family: "Rubik", sans-serif;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: $primaryColor #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

*::-webkit-scrollbar-track {
	background: #ffffff;
}

*::-webkit-scrollbar-thumb {
	background-color: $primaryColor;
	border-radius: 5px;
	border: 2px solid #ffffff;
}


.fps {
	&--background {
		background: $gray-50;
	}

	&--card {
		background: white;
		width: 400px;
		height: auto;
		padding: 16px;
		border: 1px solid $gray-200;
		border-radius: 10px;
	}

	&--card-variant {
		@extend .fps--card;
		padding: 24px;
		border-radius: 0;
	}

	&__button {
		&--normal {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			color: #FFFFFF;
		}

		&--secondary {
			@extend .fps__button--normal;
			color: $primary-600;
			background-color: transparent;

			&:hover {
				color: $primary-600 !important;
				background-color: $primary-25 !important;
			}
		}

		&--secondary-no-border {
			@extend .fps__button--secondary;
			border: none;
		}
	}

	&__text {
		&--regular {
			font-family: 'Rubik', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			text-align: center;
			color: $gray-500;
		}

		&--small {
			font-family: 'Rubik', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			color: #000000;
		}

		&--small-gray {
			@extend .fps__text--small;
			color: $gray-400;
		}

		&--small-lightred {
			@extend .fps__text--small;
			color: $primary-400;
		}

		&--medium {
			font-family: 'Rubik', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: #000000;
		}

		&--big {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 150%;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			color: #000000;
		}

		&--title {
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 35px;
			line-height: 100%;
			color: #212121;
		}

		&--bold {
			font-family: 'Montserrat', sans-serif;
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 150%;
			color: $text-neutral-black;
		}

		&--placeholder-icon {
			font-size: 16px;
			color: $gray-400 !important;
		}

		&--medium-bold {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 600;
			font-size: 17px;
			line-height: 113%;
			color: $text-neutral-black;
		}

		&--title-medium-bold {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 600;
			font-size: 24px;
			line-height: 150%;
			color: #000000;
		}

		&--primary {
			font-family: 'Inter', "sans-serif";
			font-style: normal;
			font-weight: 600;
			font-size: 17px;
			line-height: 130%;
			color: $primaryColor;
			transform: translateX(0);
			transition: all 0.2s ease;

			&:hover {
				transform: translateX(3px)
			}
		}

		&--subtitle {
			font-family: Rubik, sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: $gray-500;
		}

		&--description {
			font-family: 'Inter', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 17px;
			line-height: 100%;
			color: #495057;
		}


		&--label {
			font-family: Inter, sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0px;
			text-align: left;
			color: $text-neutral-grey;
		}

		&--link {
			font-family: 'Rubik', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			text-align: center;
			text-decoration-line: underline;
			color: $primary-600;
			cursor: pointer;
		}
	}
}

.p-button {
	@extend .fps__button--normal;
}

.p-tag .p-tag-value {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 110%;
	text-align: center;
	text-transform: uppercase;
}

.p-inputtext {
	border: 1px solid $gray-300;
	height: 50px;
	border-radius: 8px;
}

* {
	//&::-webkit-scrollbar {
	//    display: none;
	//}
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	//-ms-overflow-style: none; /* IE and Edge */
	//scrollbar-width: none; /* Firefox */
}

.p-dropdown-panel {
	margin-top: 8px;
}
