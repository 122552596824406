.p-contextmenu {
	padding: $verticalMenuPadding;
	background: $overlayMenuBg;
	color: $menuTextColor;
	border: $overlayMenuBorder;
	box-shadow: $overlayMenuShadow;
	width: $menuWidth;

	.p-menuitem-link {
		@include menuitem-link();
	}

	.p-submenu-list {
		padding: $verticalMenuPadding;
		background: $overlayMenuBg;
		border: $overlayMenuBorder;
		box-shadow: $overlayMenuShadow;
	}

	.p-menuitem {
		&.p-menuitem-active {
			> .p-menuitem-link {
				background: $menuitemActiveBg;

				.p-menuitem-text {
					color: $menuitemTextActiveColor;
				}

				.p-menuitem-icon, .p-submenu-icon {
					color: $menuitemIconActiveColor;
				}
			}
		}
	}

	.p-menu-separator {
		border-top: $divider;
		margin: $menuSeparatorMargin;
	}

	.p-submenu-icon {
		font-size: $menuitemSubmenuIconFontSize;
	}
}
