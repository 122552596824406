@use "src/theme/variables" as *;
@use "src/theme/mixins/breakpoint" as *;

.HomeContent {

	&-reduced {
		width: calc(100% - 250px);
	}

	&-expanded {
		width: calc(100% - 72px);
	}

	background: $bg-primary-color;
	overflow: auto;


	&--wrapper {
		margin: 28px 0 28px 56px;
		width: calc(100% - 112px);
		height: auto;
	}
}
