.p-colorpicker-preview {
	width: $colorPickerPreviewWidth;
	height: $colorPickerPreviewHeight;
}

.p-colorpicker-panel {
	background: $colorPickerBg;
	border-color: $colorPickerBorderColor;

	.p-colorpicker-color-handle,
	.p-colorpicker-hue-handle {
		border-color: $colorPickerHandleColor;
	}
}

.p-colorpicker-overlay-panel {
	box-shadow: $inputOverlayShadow;
}
