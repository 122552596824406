.p-picklist {
	.p-picklist-buttons {
		padding: $panelContentPadding;

		.p-button {
			margin-bottom: $inlineSpacing;
		}
	}

	.p-picklist-header {
		background: $panelHeaderBg;
		color: $panelHeaderTextColor;
		border: $panelHeaderBorder;
		padding: $panelHeaderPadding;
		font-weight: $panelHeaderFontWeight;
		border-bottom: 0 none;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;
	}

	.p-picklist-list {
		border: $panelContentBorder;
		background: $panelContentBg;
		color: $panelContentTextColor;
		padding: $inputListPadding;
		border-bottom-right-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;

		.p-picklist-item {
			padding: $inputListItemPadding;
			margin: $inputListItemMargin;
			border: $inputListItemBorder;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
			transition: transform $transitionDuration, $listItemTransition;

			&:not(.p-highlight):hover {
				background: $inputListItemHoverBg;
				color: $inputListItemTextHoverColor;
			}

			&:focus {
				@include focused-listitem();
			}

			&.p-highlight {
				color: $highlightTextColor;
				background: $highlightBg;
			}
		}
	}
}

@media screen and (max-width: $pickListBreakpoint) {
	.p-picklist {
		flex-direction: column;

		.p-picklist-buttons {
			padding: $panelContentPadding;
			flex-direction: row;

			.p-button {
				margin-right: $inlineSpacing;
				margin-bottom: 0;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.p-picklist-transfer-buttons {
			.pi-angle-right {
				@include icon_override('\e930');
			}

			.pi-angle-double-right {
				@include icon_override('\e92c');
			}

			.pi-angle-left {
				@include icon_override('\e933');
			}

			.pi-angle-double-left {
				@include icon_override('\e92f');
			}
		}
	}
}
