@use 'src/theme/variables' as *;

#LeftMenu {
	width: 250px;
	background: white;

	.menu {
		&__inner {
			&--logo {
				&-cta-close, &-cta-open {
					padding: 14px;
					width: 42px;
					color: $text-neutral-grey;
					transform: translateX(0);
					transition: transform 0.2s linear;
				}

				&-cta-close:hover {
					transform: translateX(-2px);
					color: $primaryColor;
				}

				&-cta-open:hover {
					transform: translateX(2px);
					color: $primaryColor;
				}

				img {
					width: 112px;
					height: 25px;
				}
			}

			&__wrapper {

			}
		}
	}

}
