.p-inputswitch {
	width: $inputSwitchWidth;
	height: $inputSwitchHeight;

	.p-inputswitch-slider {
		background: $inputSwitchSliderOffBg;
		transition: $formElementTransition;
		border-radius: $inputSwitchBorderRadius;

		&:before {
			background: $inputSwitchHandleOffBg;
			width: $inputSwitchHandleWidth;
			height: $inputSwitchHandleHeight;
			left: $inputSwitchSliderPadding;
			margin-top: -1 * $inputSwitchHandleHeight * 0.5;
			border-radius: $inputSwitchHandleBorderRadius;
			transition-duration: $transitionDuration;
		}
	}

	&.p-inputswitch-checked {
		.p-inputswitch-slider:before {
			transform: translateX($inputSwitchHandleWidth);
		}
	}

	&.p-focus {
		.p-inputswitch-slider {
			@include focused();
		}
	}

	&:not(.p-disabled):hover {
		.p-inputswitch-slider {
			background: $inputSwitchSliderOffHoverBg;
		}
	}

	&.p-inputswitch-checked {
		.p-inputswitch-slider {
			background: $inputSwitchSliderOnBg;

			&:before {
				background: $inputSwitchHandleOnBg;
			}
		}

		&:not(.p-disabled):hover {
			.p-inputswitch-slider {
				background: $inputSwitchSliderOnHoverBg;
			}
		}
	}

	&.p-invalid {
		@include invalid-input();
	}
}
