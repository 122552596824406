@import "src/theme/variables";

.OrderDetail {
  &-header {
    &-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 150%;
      color: #000000;
    }

    &-subtitle {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: #000000;
    }

    &-text {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #475467;
    }
  }


  &-vertical-divider {
    height: available;
    width: 0.5px;
    border-left: solid $text-subtle-grey 0.5px;
  }

  &-grid{
    display: grid;
    grid-template-columns: 20% 40% 20% 20%;
    grid-template-rows: auto;
    &-header{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #000000;
      text-align: start;
      margin-bottom: 14px;
    }
    &-item{
      margin-bottom: 12px;
      padding-bottom: 15px;
      font-family: 'Inter';
      text-align: start;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #475467;
      border-bottom: 1px solid #EAECF0;
    }

  }
}
