.p-tag {
	background: $badgeBg;
	color: $badgeTextColor;
	font-size: 12px;
	font-weight: 600;
	padding: $tagPadding;
	border-radius: 4px;

	&.p-tag-success {
		background-color: #C4FCCD;
		color: #157831;
	}

	&.p-tag-info {
		background: #DCEDF9;
		color: #3498DB;
	}

	&.p-tag-warning {
		background-color: #FCE9C4;
		color: #785715;
	}

	&.p-tag-danger {
		background-color: #FCC4C4;
		color: #781515;
	}

	.p-tag-icon {
		margin-right: $inlineSpacing * 0.5;
		font-size: $badgeFontSize;
	}
}
