.p-autocomplete {
	&-multiple {
		width: 100%;
		min-height: 40px;
		height: auto;

		&-container {
			width: 100%;
			height: auto;
		}
	}

	&-token {
		margin-top: 4px;
		margin-bottom: 4px;
	}
}
