
.ProfileDetail {
  &-header {
    &-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #000000;
    }

    &-subtitle {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #475467;
    }

    &-subtitle__black {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
    }
  }

  &--btn-secondary {
    background-color: #475467 !important;
    border: none !important;
    color: white !important;

    &:hover {
      background-color: #475467 !important;
      color: white !important;
    }
  }
}
