.p-chip {
	background-color: $chipBg;
	color: $chipTextColor;
	border-radius: $chipBorderRadius;
	padding: 0 nth($inputPadding, 2);

	.p-chip-text {
		line-height: 1.5;
		margin-top: nth($inputPadding, 1) * 0.5;
		margin-bottom: nth($inputPadding, 1) * 0.5;
	}

	.p-chip-icon {
		margin-right: $inlineSpacing;
	}

	img {
		width: 1.5 + nth($inputPadding, 1);
		height: 1.5 + nth($inputPadding, 1);
		margin-left: -1 * nth($inputPadding, 2);
		margin-right: $inlineSpacing;
	}

	.p-chip-remove-icon {
		border-radius: $borderRadius;
		transition: $actionIconTransition;
		margin-left: $inlineSpacing;

		&:focus {
			@include focused();
		}
	}
}
