.p-menu {
	padding: $verticalMenuPadding;
	background: $menuBg;
	color: $menuTextColor;
	border: $menuBorder;
	border-radius: $borderRadius;
	width: $menuWidth;
	font-family: Roboto;

	.p-menuitem-link {
		@include menuitem-link();
	}

	&.p-menu-overlay {
		background: $overlayMenuBg;
		border: $overlayMenuBorder;
		box-shadow: $overlayMenuShadow;
		border-radius: 8px;
	}

	.p-submenu-header {
		margin: $submenuHeaderMargin;
		padding: $submenuHeaderPadding;
		color: $submenuHeaderTextColor;
		background: $submenuHeaderBg;
		font-weight: $submenuHeaderFontWeight;
		border-top-right-radius: $submenuHeaderBorderRadius;
		border-top-left-radius: $submenuHeaderBorderRadius;
	}

	.p-menu-separator {
		border-top: $divider;
		margin: $menuSeparatorMargin;
	}
}
