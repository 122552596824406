.p-multiselect {
	background: $inputBg;
	border: $inputBorder;
	transition: $formElementTransition;
	border-radius: $borderRadius;

	&:not(.p-disabled):hover {
		border-color: $inputHoverBorderColor;
	}

	&:not(.p-disabled).p-focus {
		@include focused-input();
	}

	&.p-multiselect-clearable {
		.p-multiselect-label {
			padding-right: nth($inputPadding, 2) + $primeIconFontSize;
		}
	}

	.p-multiselect-label {
		padding: $inputPadding;
		transition: $formElementTransition;

		&.p-placeholder {
			color: $inputPlaceholderTextColor;
		}
	}

	&.p-multiselect-chip {
		.p-multiselect-token {
			padding: calc(nth($inputPadding, 1) / 2) nth($inputPadding, 2);
			margin-right: $inlineSpacing;
			background: $highlightBg;
			color: $highlightTextColor;
			border-radius: $borderRadius;

			.p-multiselect-token-icon {
				margin-left: $inlineSpacing;
			}
		}
	}

	.p-multiselect-trigger {
		background: transparent;
		color: $inputIconColor;
		width: $inputGroupAddOnMinWidth;
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}

	.p-multiselect-clear-icon {
		color: $inputIconColor;
		right: $inputGroupAddOnMinWidth;
	}

	&.p-invalid.p-component {
		@include invalid-input();
	}
}

.p-inputwrapper-filled {
	&.p-multiselect {
		&.p-multiselect-chip {
			.p-multiselect-label {
				padding: calc(nth($inputPadding, 1) / 2) nth($inputPadding, 2);

				&.p-multiselect-items-label {
					padding: $inputPadding;
				}
			}
		}

		&.p-multiselect-clearable {
			.p-multiselect-label {
				padding-right: nth($inputPadding, 2) + $primeIconFontSize;
			}
		}
	}
}

.p-multiselect-panel {
	background: $inputOverlayBg;
	color: $inputListTextColor;
	border: $inputOverlayBorder;
	border-radius: $borderRadius;
	box-shadow: $inputOverlayShadow;

	.p-multiselect-header {
		padding: $inputListHeaderPadding;
		border-bottom: $inputListHeaderBorder;
		color: $inputListHeaderTextColor;
		background: $inputOverlayHeaderBg;
		margin: $inputListHeaderMargin;
		border-top-right-radius: $borderRadius;
		border-top-left-radius: $borderRadius;

		.p-multiselect-filter-container {
			margin: 0 $inlineSpacing;

			.p-inputtext {
				padding-right: nth($inputPadding, 2) + $primeIconFontSize;
			}

			.p-multiselect-filter-icon {
				right: nth($inputPadding, 2);
				color: $inputIconColor;
			}
		}

		.p-multiselect-close {
			@include action-icon();
		}
	}

	.p-multiselect-items {
		padding: $inputListPadding;

		.p-multiselect-item {
			margin: $inputListItemMargin;
			padding: $inputListItemPadding;
			border: $inputListItemBorder;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
			transition: $listItemTransition;
			border-radius: $inputListItemBorderRadius;

			&.p-highlight {
				color: $highlightTextColor;
				background: $highlightBg;
			}

			&:not(.p-highlight):not(.p-disabled):hover {
				color: $inputListItemTextHoverColor;
				background: $inputListItemHoverBg;
			}

			&:focus {
				@include focused-listitem();
			}

			.p-checkbox {
				margin-right: $inlineSpacing;
			}
		}

		.p-multiselect-item-group {
			margin: $submenuHeaderMargin;
			padding: $submenuHeaderPadding;
			color: $submenuHeaderTextColor;
			background: $submenuHeaderBg;
			font-weight: $submenuHeaderFontWeight;
		}

		.p-multiselect-empty-message {
			padding: $inputListItemPadding;
			color: $inputListItemTextColor;
			background: $inputListItemBg;
		}
	}
}

.p-input-filled {
	.p-multiselect {
		background: $inputFilledBg;

		&:not(.p-disabled):hover {
			background-color: $inputFilledHoverBg;
		}

		&:not(.p-disabled).p-focus {
			background-color: $inputFilledFocusBg;
		}
	}
}
