@use 'src/theme/variables' as *;

.CustomTable {
	&__header {

		.p-dropdown {
			height: 40px;
			border: 1px solid #CED4DA;
			min-width: 8rem;
		}

		&--input {
			width: 197px;
			height: 40px;

			input {
				height: 40px;
				width: 197px;
				border: 1px solid #CED4DA !important;
			}

			i {
				color: $text-neutral-grey;
				font-size: 15px;
			}
		}

		&--action {
			.p-button {
				height: 40px;
			}
		}
	}

}
