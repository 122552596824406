
.ProductDetails {
	&-header {
		&-title {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 150%;
			color: #000000;
		}

		&-subtitle {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			color: #475467;
		}
	}

	&-body {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: 36px;

		&-item {
			grid: span 1/1;
			border-bottom: 1px solid #EAECF0;

			&-left {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: #475467;
			}

			&-right {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 150%;
				color: #000000;
			}
		}
	}
}
