.p-checkbox {
	width: $checkboxWidth;
	height: $checkboxHeight;

	.p-checkbox-box {
		border: $checkboxBorder;
		background: $inputBg;
		width: $checkboxWidth;
		height: $checkboxHeight;
		color: $textColor;
		border-radius: $borderRadius;
		transition: $formElementTransition;

		.p-checkbox-icon {
			transition-duration: $transitionDuration;
			color: $checkboxIconActiveColor;
			font-size: $checkboxIconFontSize;
		}

		&.p-highlight {
			border-color: $checkboxActiveBorderColor;
			background: $checkboxActiveBg;

			&:not(.p-disabled):hover {
				border-color: $checkboxActiveHoverBorderColor;
				background: $checkboxActiveHoverBg;
				color: $checkboxIconActiveHoverColor;
			}
		}
	}

	&:not(.p-checkbox-disabled) {
		.p-checkbox-box {
			&:hover {
				border-color: $inputHoverBorderColor;
			}

			&.p-focus {
				@include focused-input();
			}

			&.p-highlight:hover {
				border-color: $checkboxActiveHoverBorderColor;
				background: $checkboxActiveHoverBg;
				color: $checkboxIconActiveHoverColor;
			}
		}
	}

	&.p-invalid > .p-checkbox-box {
		@include invalid-input();
	}
}

.p-input-filled {
	.p-checkbox {
		.p-checkbox-box {
			background-color: $inputFilledBg;

			&.p-highlight {
				background: $checkboxActiveBg;
			}
		}

		&:not(.p-checkbox-disabled) {
			.p-checkbox-box {
				&:hover {
					background-color: $inputFilledHoverBg;
				}

				&.p-highlight:hover {
					background: $checkboxActiveHoverBg;
				}
			}
		}
	}
}

@if ($highlightBg == $checkboxActiveBg) {
	.p-highlight {
		.p-checkbox {
			.p-checkbox-box {
				border-color: $checkboxIconActiveColor;
			}
		}
	}
}
